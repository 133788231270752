.input-search {
  width: fit-content;
  box-sizing: border-box;
  place-content: center;
  padding: 0.5rem;
  border-style: none;
  background-color: transparent;
  border-bottom: 1px solid hsl(0, 5%, 74%);
  margin: 0.5rem;
}
::placeholder {
  font-weight: bold;
  color: #474141;
  font-family: "Raleway", sans-serif;
}
