.span-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: 0;
  padding-left: 1rem;
}

.div-intro {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-info {
  border-color: hsl(0, 12%, 92%);
  border-style: solid;
  border-width: 1px;
  margin-top: 2.5rem;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 1rem;
}

div > span:first-of-type {
  padding-top: 2rem;
  font-size: xx-large;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 700;
}

div > span {
  margin-bottom: 0.5rem;
}
div > span:last-of-type {
  margin-bottom: -1.5rem;
}

.padding-left {
  padding-left: 1rem;
}

li {
  max-width: 50em;
}

.line {
  /* width: auto; */
  padding: 0;
  /* height: 20px; */
  border-bottom: 1px solid hsl(0, 12%, 92%);
  margin: 0;
}

.btn-test {
  margin-right: 2rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 15px;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  color: cadetblue;
  background: whitesmoke;
  font: 500 24px sans-serif;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.btn-test:hover {
  color: black;
}

.btn-test:active {
  color: black;
  background-color: whitesmoke;
}

.btn-tag {
  padding: 0.5rem;
  margin-right: 0.5rem;
}

.input-tag {
  margin: 0.5rem;
  padding: 0.5rem;
  border-style: none;
  background-color: transparent;
  border-bottom: 1px solid hsl(0, 5%, 74%);
}
