:root {
  --bg-color: #efefef;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--bg-color);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: "Raleway", sans-serif;
}

section {
  padding-top: 3rem;
  margin-bottom: 5rem;
}

.center {
  overflow: auto;
  -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
  scrollbar-width: none; /* Hide scrollbar Firefox */
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 50em;
  width: 90%;
  margin: 0 auto;
  padding: 10 0 0 0;

  scroll-behavior: smooth;
  height: 45em;
}

/* Hide scrollbar Chrome, Opera */
.center::-webkit-scrollbar {
  display: none;
}

.app-ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  height: 39em;
}

.app-search-div {
  position: relative;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
